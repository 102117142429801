

























































import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "btn-remove-nomenclator",
  props: {
    id: Number,
    type: String,
    name: String,
  },
  data() {
    return {
      dialogConfirmRemove: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actRemoveNomenclator"]),
    remove() {
      this.loading = true;
      this.actRemoveNomenclator({
        id: this.id,
        type: this.type,
      })
        .then(() => {
          this.dialogConfirmRemove = false;
          this.loading = false;
          notifyInfo(this.$t("notifyRemove"));
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancel() {
      this.dialogConfirmRemove = false;
      this.$emit("dialog", false);
    },
  },
});
